import React from "react"

type Props = {
  title: string
}

const HeadlineM = ({ title }: Props) => {
  return (
    <div className="text-3xl text-center md:whitespace-pre-wrap font-ZenMaruGathic text-DeepGreen">
      {title}
    </div>
  )
}

export default HeadlineM
