import React from "react"
import HeadlineM from "../components/HeadlineM"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="my-24 md:my-36">
      <HeadlineM title="404" />
      <p className="mt-10 text-center">ページが見つかりませんでした。</p>
    </div>
  </Layout>
)

export default NotFoundPage
